@import url("//fonts.googleapis.com/css?family=Raleway:400");
@-webkit-keyframes ui-spinner-rotate-right {
  /* line 66, ../../src/sass/messenger-spinner.scss */
  0% {
    -webkit-transform: rotate(0deg);
  }

  /* line 67, ../../src/sass/messenger-spinner.scss */
  25% {
    -webkit-transform: rotate(180deg);
  }

  /* line 68, ../../src/sass/messenger-spinner.scss */
  50% {
    -webkit-transform: rotate(180deg);
  }

  /* line 69, ../../src/sass/messenger-spinner.scss */
  75% {
    -webkit-transform: rotate(360deg);
  }

  /* line 70, ../../src/sass/messenger-spinner.scss */
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes ui-spinner-rotate-left {
  /* line 74, ../../src/sass/messenger-spinner.scss */
  0% {
    -webkit-transform: rotate(0deg);
  }

  /* line 75, ../../src/sass/messenger-spinner.scss */
  25% {
    -webkit-transform: rotate(0deg);
  }

  /* line 76, ../../src/sass/messenger-spinner.scss */
  50% {
    -webkit-transform: rotate(180deg);
  }

  /* line 77, ../../src/sass/messenger-spinner.scss */
  75% {
    -webkit-transform: rotate(180deg);
  }

  /* line 78, ../../src/sass/messenger-spinner.scss */
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes ui-spinner-rotate-right {
  /* line 82, ../../src/sass/messenger-spinner.scss */
  0% {
    -moz-transform: rotate(0deg);
  }

  /* line 83, ../../src/sass/messenger-spinner.scss */
  25% {
    -moz-transform: rotate(180deg);
  }

  /* line 84, ../../src/sass/messenger-spinner.scss */
  50% {
    -moz-transform: rotate(180deg);
  }

  /* line 85, ../../src/sass/messenger-spinner.scss */
  75% {
    -moz-transform: rotate(360deg);
  }

  /* line 86, ../../src/sass/messenger-spinner.scss */
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-moz-keyframes ui-spinner-rotate-left {
  /* line 90, ../../src/sass/messenger-spinner.scss */
  0% {
    -moz-transform: rotate(0deg);
  }

  /* line 91, ../../src/sass/messenger-spinner.scss */
  25% {
    -moz-transform: rotate(0deg);
  }

  /* line 92, ../../src/sass/messenger-spinner.scss */
  50% {
    -moz-transform: rotate(180deg);
  }

  /* line 93, ../../src/sass/messenger-spinner.scss */
  75% {
    -moz-transform: rotate(180deg);
  }

  /* line 94, ../../src/sass/messenger-spinner.scss */
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes ui-spinner-rotate-right {
  /* line 98, ../../src/sass/messenger-spinner.scss */
  0% {
    transform: rotate(0deg);
  }

  /* line 99, ../../src/sass/messenger-spinner.scss */
  25% {
    transform: rotate(180deg);
  }

  /* line 100, ../../src/sass/messenger-spinner.scss */
  50% {
    transform: rotate(180deg);
  }

  /* line 101, ../../src/sass/messenger-spinner.scss */
  75% {
    transform: rotate(360deg);
  }

  /* line 102, ../../src/sass/messenger-spinner.scss */
  100% {
    transform: rotate(360deg);
  }
}
@keyframes ui-spinner-rotate-left {
  /* line 106, ../../src/sass/messenger-spinner.scss */
  0% {
    transform: rotate(0deg);
  }

  /* line 107, ../../src/sass/messenger-spinner.scss */
  25% {
    transform: rotate(0deg);
  }

  /* line 108, ../../src/sass/messenger-spinner.scss */
  50% {
    transform: rotate(180deg);
  }

  /* line 109, ../../src/sass/messenger-spinner.scss */
  75% {
    transform: rotate(180deg);
  }

  /* line 110, ../../src/sass/messenger-spinner.scss */
  100% {
    transform: rotate(360deg);
  }
}
/* line 118, ../../src/sass/messenger-spinner.scss */
.messenger-spinner {
  position: relative;
  border-radius: 100%;
}
/* line 122, ../../src/sass/messenger-spinner.scss */
ul.messenger.messenger-spinner-active .messenger-spinner .messenger-spinner {
  display: block;
}
/* line 126, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
}
/* line 132, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side .messenger-spinner-fill {
  border-radius: 999px;
  position: absolute;
  width: 100%;
  height: 100%;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
/* line 142, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-left {
  left: 0;
}
/* line 145, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-left .messenger-spinner-fill {
  left: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-animation-name: ui-spinner-rotate-left;
  -webkit-animation-name: ui-spinner-rotate-left;
  animation-name: ui-spinner-rotate-left;
  -moz-transform-origin: 0 50% 50%;
  -ms-transform-origin: 0 50% 50%;
  -webkit-transform-origin: 0 50% 50%;
  transform-origin: 0 50% 50%;
}
/* line 154, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-right {
  left: 50%;
}
/* line 157, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-right .messenger-spinner-fill {
  left: -100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -moz-animation-name: ui-spinner-rotate-right;
  -webkit-animation-name: ui-spinner-rotate-right;
  animation-name: ui-spinner-rotate-right;
  -moz-transform-origin: 100% 50% 50%;
  -ms-transform-origin: 100% 50% 50%;
  -webkit-transform-origin: 100% 50% 50%;
  transform-origin: 100% 50% 50%;
}

/* line 16, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air {
  -moz-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  font-family: "Raleway", sans-serif;
}
/* line 20, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message {
  -moz-transition: background-color 0.4s;
  -o-transition: background-color 0.4s;
  -webkit-transition: background-color 0.4s;
  transition: background-color 0.4s;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: inset 0 0 0 1px white, inset 0 2px white, 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 0 1px white, inset 0 2px white, 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 0 1px white, inset 0 2px white, 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px rgba(0, 0, 0, 0.2);
  border: 0px;
  background-color: rgba(255, 255, 255, 0.8);
  position: relative;
  margin-bottom: 1em;
  font-size: 13px;
  color: #666666;
  font-weight: 500;
  padding: 10px 30px 11px 46px;
}
/* line 33, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message:hover {
  background-color: white;
}
/* line 36, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message .messenger-close {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #888888;
  opacity: 1;
  font-weight: bold;
  display: block;
  font-size: 20px;
  line-height: 20px;
  padding: 8px 10px 7px 7px;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
/* line 52, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message .messenger-close:hover {
  color: #444444;
}
/* line 55, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message .messenger-close:active {
  color: #222222;
}
/* line 58, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message .messenger-actions {
  float: none;
  margin-top: 10px;
}
/* line 62, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message .messenger-actions a {
  -moz-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), inset 0px 1px rgba(255, 255, 255, 0.05);
  -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), inset 0px 1px rgba(255, 255, 255, 0.05);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), inset 0px 1px rgba(255, 255, 255, 0.05);
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  padding: 10px;
  color: #888888;
  margin-right: 10px;
  padding: 3px 10px 5px;
  text-transform: capitalize;
}
/* line 73, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message .messenger-actions a:hover {
  -moz-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), inset 0px 1px rgba(255, 255, 255, 0.15);
  -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), inset 0px 1px rgba(255, 255, 255, 0.15);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), inset 0px 1px rgba(255, 255, 255, 0.15);
  color: #444444;
}
/* line 77, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message .messenger-actions a:active {
  -moz-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.18), inset 0px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.18), inset 0px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.18), inset 0px 1px rgba(0, 0, 0, 0.05);
  background: rgba(0, 0, 0, 0.04);
  color: #444444;
}
/* line 82, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message .messenger-actions .messenger-phrase {
  display: none;
}
/* line 85, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message .messenger-message-inner:before {
  -moz-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  left: 17px;
  display: block;
  content: " ";
  top: 50%;
  margin-top: -8px;
  height: 13px;
  width: 13px;
  z-index: 20;
}
/* line 99, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message.alert-success .messenger-message-inner:before {
  background-color: #5fca4a;
}
/* line 34, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-air .messenger-message.alert-error.messenger-retry-soon .messenger-spinner {
  width: 24px;
  height: 24px;
  background: transparent;
}
/* line 39, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-air .messenger-message.alert-error.messenger-retry-soon .messenger-spinner .messenger-spinner-side .messenger-spinner-fill {
  background: #dd6a45;
  -moz-animation-duration: 20s;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  opacity: 1;
}
/* line 47, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-air .messenger-message.alert-error.messenger-retry-soon .messenger-spinner:after {
  content: "";
  background: white;
  position: absolute;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  display: block;
}
/* line 34, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-air .messenger-message.alert-error.messenger-retry-later .messenger-spinner {
  width: 24px;
  height: 24px;
  background: transparent;
}
/* line 39, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-air .messenger-message.alert-error.messenger-retry-later .messenger-spinner .messenger-spinner-side .messenger-spinner-fill {
  background: #dd6a45;
  -moz-animation-duration: 600s;
  -webkit-animation-duration: 600s;
  animation-duration: 600s;
  opacity: 1;
}
/* line 47, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-air .messenger-message.alert-error.messenger-retry-later .messenger-spinner:after {
  content: "";
  background: white;
  position: absolute;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  display: block;
}
/* line 109, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message.alert-error .messenger-message-inner:before {
  background-color: #dd6a45;
}
/* line 113, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-message.alert-info .messenger-message-inner:before {
  background-color: #61c4b8;
}
/* line 116, ../../src/sass/messenger-theme-air.sass */
ul.messenger-theme-air .messenger-spinner {
  display: block;
  position: absolute;
  left: 12px;
  top: 50%;
  margin-top: -13px;
  z-index: 999;
  height: 24px;
  width: 24px;
  z-index: 10;
}
